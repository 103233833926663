import Cookie from 'js-cookie';
import i18n from 'i18next';

import { http, ContentType } from '../Utils';
import { serviceConfig } from '../GlobalConfig';


interface Callback {
	onSuccess: (value?: any) => void;
	onError: (value: any) => void;
}

export default class User {

	id: string;
	name: string;
	email: string;

	constructor(obj: any) {
		this.id = obj.id;
		this.name = obj.name;
		this.email = obj.email;
	}

	static me = () => {
		try {
			const info = Cookie.get('user_info')!;
			const obj = JSON.parse(atob(info));
			const user = new User(obj);
			return user;
		} catch (error) {
			return undefined;
		}
	}

	static signIn = (email: string, password: string, remember: boolean, callback?: Callback) => {
		const host = serviceConfig.backendhost + '/api/login';
		http(host, {
			body: { email: email, pwd: password, rememberOneWeek: remember, lng: i18n.resolvedLanguage },
			method: 'POST',
			contentType: ContentType.form,
		}).then((res) => {
			if (res.success) {
				callback && (res.msg === 'needVerifyEmail' ? callback.onError(res.msg) : callback.onSuccess());
			} else {
				callback && callback.onError(res.msg);
			}
		});
	};

	static signUp = (email: string, password: string, callback?: Callback) => {
		const host = serviceConfig.backendhost + '/api/login?signup=true';
		http(host, {
			body: { email: email, pwd: password, lng: i18n.resolvedLanguage },
			method: 'POST',
			contentType: ContentType.form,
		}).then((res) => {
			if (res.success) {
				callback && callback.onSuccess();
			} else {
				callback && callback.onError(res.msg);
			}
		});
	};

	signOut = (callback?: Callback) => {
		const host = serviceConfig.backendhost + "/api/logout";
		http(host, {
			body: {},
			method: "POST",
			contentType: ContentType.form,
		}).then((res) => {
			if (res.success) {
				callback && callback.onSuccess();
			} else {
				callback && callback.onError(res.msg);
			}
		})
	}

	// Todo: Merge checkEmail and resendEmail in the future.

	static checkEmail = (email: string, callback?: Callback) => {
		const host = serviceConfig.backendhost + '/api/forgetpassword';
		http(host, {
			body: { email: email, lng: i18n.resolvedLanguage },
			method: 'POST',
			contentType: ContentType.json,
		}).then((res) => {
			if (res.success) {
				callback && callback.onSuccess();
			} else {
				callback && callback.onError(res.msg);
			}
		});
	};

	static resendEmail = (email: string, callback?: Callback) => {
		const host = serviceConfig.backendhost + '/api/resendemail';
		http(host, {
			body: { email: email, lng: i18n.resolvedLanguage },
			method: 'POST',
			contentType: ContentType.json,
		}).then((res) => {
			if (res.success) {
				callback && callback.onSuccess();
			} else {
				callback && callback.onError(res.msg);
			}
		});
	};

	// Todo: remove "clear" in the future, control on server side instead.

	static verifyEmail = (key: string, clear: boolean, callback?: Callback) => {
		const host = serviceConfig.backendhost + '/api/verify-email';
		http(host, {
			body: { key: key, clear: clear },
			method: 'POST',
			contentType: ContentType.json,
		}).then((res) => {
			if (res.success) {
				callback && callback.onSuccess();
			} else {
				callback && callback.onError(res.msg);
			}
		});
	};

	static resetPassword = (password: string, key: string, callback?: Callback) => {
		const host = serviceConfig.backendhost + '/api/reset-password';
		http(host, {
			body: { pwd: password, key: key },
			method: 'POST',
			contentType: ContentType.json,
		}).then((res) => {
			if (res.success) {
				callback && callback.onSuccess();
			} else {
				callback && callback.onError(res.msg);
			}
		});
	};

}