import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { AppWorkspaceContext } from './AppWorkspace';
import Data from '../models/Data';
import dayjs from 'dayjs';

import FilterToolbar from './FilterToolbar';
import DataCard from './DataCard';
import WorkspaceData from './WorkspaceData';
import { WorkspaceSection } from './WorkspaceSection';
import { NotifyExpired, NotifyTestMode } from './Notifications';
import { Grid, CircularProgress, Box, Chip } from '@mui/joy';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleMinus } from '@fortawesome/free-solid-svg-icons';
import { GridColDef } from '@mui/x-data-grid';
import { LineChart } from '@mui/x-charts/LineChart';

import {
  	Experimental_CssVarsProvider as CssVarsProvider,
  	experimental_extendTheme as extendTheme,
} from '@mui/material/styles';
const theme = extendTheme();

const AppActivites: React.FC = () => {
	const app = React.useContext(AppWorkspaceContext)!;

	const { t } = useTranslation(['dataCard', 'dashboard', 'common']);

	const allSeries = React.useMemo(()=>{
		return Array.from<any>([
			{
				label: t('ad.title'),
				dataKey: 'download',
				color: '#EF57A1',
				curve: 'catmullRom',
				yAxisKey: 'leftAxisId',
				showMark: false,
			},
			{
				label: t('ai.title'),
				dataKey: 'install',
				color: '#FFA5A5',
				curve: 'catmullRom',
				yAxisKey: 'leftAxisId',
				showMark: false,
			},
			{
				label: t('ao.title'),
				dataKey: 'open',
				color: '#EBD494',
				curve: 'catmullRom',
				yAxisKey: 'leftAxisId',
				showMark: false,
			},
			{
				label: t('au.title'),
				dataKey: 'active',
				color: '#70EAFF',
				curve: 'catmullRom',
				yAxisKey: 'leftAxisId',
				showMark: false,
			},
			{
				label: t('d1.title'),
				dataKey: 'd1r',
				color: '#A7ABDD',
				curve: 'catmullRom',
				yAxisKey: 'rightAxisId',
				showMark: false,
				id: 'd1r',
				valueFormatter: (value: number) => `${value.toFixed(1)} %`,
			},
		]);
	}, [t]);

	const config = React.useMemo(()=>{
		return Array.from<GridColDef>([
			{ field: 'date', headerName: t('date', { ns: 'common' }), valueGetter: (value) => dayjs(value * 1000).local().format('YYYY-MM-DD') },
			{ field: 'download', headerName: t('ad.titleShort') },
			{ field: 'install', headerName: t('ai.titleShort') },
			{ field: 'open', headerName: t('ao.titleShort') },
			{ field: 'active', headerName: t('au.titleShort') },
			{ field: 'd1r', headerName: t('d1.titleShort'), valueGetter: (value: number) => `${value.toFixed(1)} %` },
		]).map((e) => {
			e.disableColumnMenu = true;
			e.resizable = false;
			e.sortable = false;
			e.flex = 1;
			e.align = 'center';
			e.headerAlign = 'center';
			return e;
		});
	}, [t]);

	const [overview, setOverview] = React.useState<Array<any>>();
	const [mostParam, setMostParam] = React.useState<{ TagName: string, TagValue: string, Count: number }>();
	const [loadingO, setLoadingO] = React.useState<boolean>(false);
	const [series, setSeries] = React.useState<Array<any>>(allSeries);
	const [usage, setUsage] = React.useState<Array<Object>>();
	const [loadingU, setLoadingU] = React.useState<boolean>(false);

	return (<>
		{app.isExpired() && <NotifyExpired />}
		{app.isTestEnabled && <NotifyTestMode />}
		<FilterToolbar allowEventTypeFilter={false} loading={loadingO||loadingU} onSubmit={(filters)=>{
			setOverview(undefined);
			setMostParam(undefined);
			setUsage(undefined);
			setLoadingO(true);
			setLoadingU(true);
			filters.appId = app.id;
			const data = new Data(filters);
			data.getOverview({
				onSuccess: (data) => {
					setLoadingO(false);
					setOverview(data);
				},
				onError: (error) => {
					setLoadingO(false);
					alert(error);
				},
			});
			data.getParameters({
				onSuccess: (data) => {
					data.sort((a: any, b: any) => b.Count - a.Count);
					setMostParam(data[0]);
				},
				onError: (error) => {
					alert(error);
				},
			});
			data.getActivities({
				onSuccess: (data) => {
					setLoadingU(false);
					data.sort((a: any, b: any) => {
						if (a.date > b.date) return -1;
						if (a.date < b.date) return 1;
						return 0;
					});	
					setUsage(data);
				},
				onError: (error) => {
					setLoadingU(false);
					alert(error);
				},
			});
		}} />
		<WorkspaceSection title={t('statisticsOverview', { ns: 'dashboard' })}>
			{(overview && <Grid container spacing={2} pl={4}>
				<Grid xs={3}><DataCard {...t('ad', { returnObjects: true })} number={overview[1]} /></Grid>
				<Grid xs={3}><DataCard {...t('ai', { returnObjects: true })} number={overview[2]} /></Grid>
				<Grid xs={3}><DataCard {...t('ao', { returnObjects: true })} number={overview[3]} /></Grid>
				<Grid xs={3}><DataCard number={mostParam?.Count ?? 0}
					title={`${mostParam?.TagName ?? 'Key'} : ${mostParam?.TagValue ?? 'Value'}`}
					subtitle={t('kv.subtitle')}
					tips={t('kv.tips')} />
				</Grid>
				<Grid xs={3}><DataCard {...t('au', { returnObjects: true })} number={overview[4]} /></Grid>
				<Grid xs={3}><DataCard {...t('d1', { returnObjects: true })} number={overview[5]} /></Grid>
				<Grid xs={3}><DataCard {...t('dw', { returnObjects: true })} number={overview[6]} /></Grid>
				<Grid xs={3}><DataCard {...t('dm', { returnObjects: true })} number={overview[7]} /></Grid>
			</Grid>) ?? (loadingO ? <CircularProgress sx={{ my: 8.5, alignSelf: 'center' }} /> : <></>)}
		</WorkspaceSection>
		<WorkspaceSection title={t('statisticsTrend', { ns: 'dashboard' })}>
			{(usage && <CssVarsProvider theme={theme}><LineChart
				height={320}
				tooltip={{ trigger: 'axis' }}
				axisHighlight={{ x: 'none', y: 'none' }}
				slotProps={{ legend: { hidden: true } }}
				grid={{ vertical: true, horizontal: true }}
				sx={{ '.MuiLineElement-series-d1r': { strokeDasharray: '4 4' } }}
				xAxis={[{
					dataKey: 'date',
					scaleType: 'band',
					reverse: true,
					valueFormatter: (value) => dayjs(value * 1000).format('YYYY-MM-DD'),
				}]}
				yAxis={[{ id: 'leftAxisId' }, { id: 'rightAxisId', valueFormatter: (value: number) => `${value} %` }]}
				rightAxis='rightAxisId'
				colors={allSeries.map(e => e.color)}
				series={series}
				dataset={usage as any}
			/></CssVarsProvider>) ?? (loadingU ? <CircularProgress sx={{ my: 4, alignSelf: 'center' }} /> : <></>)}
			{usage && <Box display='flex' justifyContent='center' my={2} gap={4}>
				{allSeries.map(e => {
					const checked = series.some(ei => e.label === ei.label);
					return (<Chip
						key={e.label}
						sx={{ '& .MuiChip-action': { backgroundColor: checked ? e.color : '#eee' } }}
						startDecorator={<FA icon={checked ? faCircleCheck : faCircleMinus} />}
						onClick={(event)=>setSeries(allSeries.filter(ei => {
							if (e.label === ei.label) return !checked;
							return series.some(eii => ei.label === eii.label);
						}))}>
						{e.label}
					</Chip>);
				})}
			</Box>}
		</WorkspaceSection>
		<WorkspaceData data={usage} config={config} loading={loadingU} />
	</>);
}

export default AppActivites;


