import * as React from 'react';
import { useTranslation } from "react-i18next";
import { useLangNavigate as useNavigate } from '../modules/i18n';

import Application from '../models/Application';

import { Colors, Dimens } from '../Resources';

import SEO from '../modules/SEO';
import NavBar from './NavBar';
import Footer from './Footer';
import AppIcon from './AppIcon';
import WorkspaceLoading from './WorkspaceLoading';
import { Workspace } from './Workspace';
import { WorkspaceSection } from './WorkspaceSection';
import { InputWithHelper } from './MoguaInput'
import { Box, BoxProps, Typography, Link, Button, Divider } from '@mui/joy';
import { Modal, ModalDialog, DialogTitle } from '@mui/joy';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome'
import { faCirclePlus, faArrowsRotate, faBan } from '@fortawesome/free-solid-svg-icons'


const Dashboard: React.FC = () => {
	const [apps, setApps] = React.useState<Array<Application> | undefined>(undefined);
	const [openCreateApp, setOpenCreateApp] = React.useState<boolean>(false);
	const { t } = useTranslation(['dashboard', 'common']);
	
	React.useEffect(() => {
		Application.all({
			onSuccess: (apps) => {
				apps.sort((a: Application, b: Application) => {
					if (a.createAt > b.createAt) return -1;
					if (a.createAt < b.createAt) return 1;
					return 0;
				});
				setApps(apps);
			},
			onError: (error) => alert(error),
		});
	}, []);

	return (<>
		<SEO
            title="Mogua | Dashboard"
            description="Welcome to Mogua."
            url="https://www.mogua.io/dashboard" />
        <NavBar wide dash />
        <Divider />
        {apps ? 
        <Workspace>
			<WorkspaceSection
				title='Apps' 
				trailing={<Button startDecorator={<FA icon={faCirclePlus} />}
				onClick={()=>setOpenCreateApp(true)}>{t('createApp')}</Button>}
				sx={{ mx: 'auto', ...Dimens.autoContentWidth }}>
				{apps.length === 0 || (apps.length === 1 && apps[0].id === '110cbcffd017') ?
				<Box mx={8} mt={6} mb={8}>
					<Typography fontSize={32} fontWeight='bold' sx={{ color: Colors.black, letterSpacing: '-0.05rem' }}>Welcome on Board!</Typography>
					<Typography sx={{ color: Colors.gray2 }}>
						To get started, either <Link component='button' onClick={()=>setOpenCreateApp(true)}>create an app</Link>, or visit the Mogua Demo app.
					</Typography>
					{apps.length === 1 && <AppCard key={apps[0].id} app={apps[0]} mt={6} />}
				</Box> :
				<Box mx='auto' my={4} display='flex' flexWrap='wrap' justifyContent='center' gap={12}>
					{apps?.map((a)=><AppCard key={a.id} app={a} />)}
					{'abcdefg'.split('').map((i)=><Box key={`placeholder_${i}`} width={128} />)}
				</Box>}
				<CreateAppDialog closeState={!openCreateApp} closeHandler={()=>setOpenCreateApp(false)} />
			</WorkspaceSection>
		</Workspace> : 
        <WorkspaceLoading label={t('loading', { ns: 'common' })} />}
        <Footer />
    </>);
}

export default Dashboard;


interface AppCardProps extends BoxProps {
	app: Application;
	iconSize?: number;
}

const AppCard: React.FC<AppCardProps> = ({ app, iconSize = 128, ...props }) => {
	const nav = useNavigate();
	return (
		<Box textAlign="center" sx={{ width: iconSize, cursor: 'pointer' }} onClick={()=>nav("/app/" + app.id)} {...props}>
			<AppIcon size={iconSize} alt={app.name} src={app.icon} />
			<Typography noWrap mt={2} fontWeight='500'>{app.name}</Typography>
			<Typography
				noWrap mx='auto' justifyContent='center'
				sx={{ color: Colors.gray2 }}
				startDecorator={app.subscription != null ? <FA icon={faArrowsRotate} /> : (app.isExpired() ? <FA icon={faBan} /> : null)}
				>{app.expireAt.toLocaleDateString()}</Typography>
		</Box>
	);
}


const CreateAppDialog: React.FC<{ closeState: boolean, closeHandler: ()=>void }> = ({ closeState, closeHandler }) => {
	const [nameError, setNameError] = React.useState<string | null>(null);
	const [loading, setLoading] = React.useState<boolean>(false);
	const { t } = useTranslation(['dashboard', 'common']);

	return (
		<Modal open={!closeState} onClose={()=>{
			setNameError(null);
			closeHandler()
		}}>
			<ModalDialog variant='soft'>
				<DialogTitle>{t('createApp')}</DialogTitle>
				<form onSubmit={(event) => {
					event.preventDefault();
					const data = new FormData(event.currentTarget);

					// Todo: refined the name regex
					const nameReg = /^.{2,20}$/;
					const name = data.get('name') as string;
					if (nameReg.test(name) === false) {
						setNameError(t('appNameRule'));
						return;
					}

					setLoading(true);
					Application.create(name, {
						onSuccess: () => {
							setLoading(false);
							closeHandler();
							window.location.reload();
						},
						onError: (error) => {
							setLoading(false);
							closeHandler();
							alert(error);
						},
					});
				}}>
					<InputWithHelper required name='name' placeholder={t('appName')} disabled={loading} errMsg={nameError} onChange={()=>setNameError(null)} />
					<Button type="submit" loading={loading} sx={{ mt: 2, width: '100%' }}>{t('create', { ns: 'common' })}</Button>
				</form>
			</ModalDialog>
		</Modal>
	);
}


