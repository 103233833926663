import * as React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useLangNavigate as useNavigate } from '../modules/i18n';

import { Colors, Dimens, AndroidSVG, AppleSVG, FlutterSVG, UnitySVG, CocosSVG } from '../Resources';

import SEO from '../modules/SEO';
import NavBar from './NavBar';
import Footer from './Footer';
import { Box, Typography, Button, Stack, Chip, Divider, Card } from '@mui/joy';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faCircleRight } from '@fortawesome/free-solid-svg-icons';


const HomeZh: React.FC = () => {
	const { t } = useTranslation('marketing');
	const nav = useNavigate();
	let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
	return <>
		<SEO
			title='Mogua: 免填邀请码'
			description="Web to app parameter passing solution. Track Android and iOS apps' installation from web referrals with our attribution SDK."
			url='https://www.mogua.io/zh' />
		<NavBar bgcolor='#071C46' fgcolor='white' />
		<Box sx={{ background: `linear-gradient(#071C46, ${Colors.blue} 75% 100%)` }}>
			<Box mt={8} mb={10} mx='auto' { ...Dimens.autoContentWidth }>
				<Box display='flex' flexDirection={{ xs: 'column', sm: 'column', md: 'row' }} alignItems='start' gap={3}>
					<Typography fontSize={72} fontWeight='bold' textColor='white' sx={{ lineHeight: 1.1, flex: 1 }}>APP渠道统计<br />免填邀请码安装</Typography>
					<Box display='flex' flexDirection='column' alignItems='center' mr={9} p={2} sx={{ borderRadius: 12, bgcolor: '#00000033' }}>
						<Typography fontSize={14} textColor='white' sx={{ opacity: 0.5 }}>MoguaSDK 支持平台及框架</Typography>
						<Stack direction='row' spacing={2} mt={1.5}>
							<AndroidSVG size='24' />
							<AppleSVG size='24' color='#dad7d0BB' />
							<FlutterSVG size='24' />
							<UnitySVG size='24' />
							<CocosSVG size='24' />
						</Stack>
					</Box>
				</Box>
				<Divider sx={{ mt: 3, background: 'linear-gradient(0.25turn, transparent, white 5%, transparent)' }} />
				<Typography fontSize={38} fontWeight='bold' textColor='white' mt={2}>网页到 APP 安装自动传参</Typography>
				<Typography fontSize={21.5} fontWeight='bold' textColor='white'>支持 iOS · Android · 应用商店安装 · 下载安装</Typography>
				<Button size='lg' endDecorator={<FA icon={faCircleRight} />} sx={{ mt: 4, border: '#071C4666 1px solid', bgcolor: Colors.greenFG }} onClick={()=>nav('/sign-up')}>
					{t('bannerButton')}
				</Button>
			</Box>
		</Box>
		<Box sx={{ flex: 1 }} />
		<Footer />
    </>;
}

export default HomeZh;


