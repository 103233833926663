import * as React from 'react';
import { useTranslation } from "react-i18next";
import { useLangNavigate as useNavigate } from '../modules/i18n';

import dayjs from 'dayjs';
import { serviceConfig } from '../GlobalConfig';
import { Colors, Dimens, MoguaSVG } from '../Resources';

import { Box, Stack, Typography, Link, Divider } from '@mui/joy';


const Footer: React.FC = () => {
    const { t } = useTranslation(['common', 'docs']);
    const docs = t('agreement', { ns: 'docs', returnObjects: true, defaultValue: [] }) as Array<any>;
    const nav = useNavigate();

    const textSx = { color: Colors.gray3, fontSize: 12, 'textDecorationColor': Colors.gray2 };
    
    return (
        <Box component='nav' display='flex' alignItems='center' justifyContent='center' sx={{ bgcolor: Colors.grayBG }}>
            <Stack flex={1} m={2} spacing={2} sx={{ maxWidth: Dimens.maxWidth }}>
                <Box display='flex' flexDirection='row'>
                    <MoguaSVG color={Colors.gray3} size='sm' />
                    {serviceConfig.version && <Box display='flex' flexDirection='column' justifyContent='center'
                        sx={{ border: 'solid 1px #00000022', borderRadius: 20, px: 1, py: 0, mx: 1 }}>
                        <Typography fontSize='xs' textColor='#00000033' >{serviceConfig.version.replace(/(mogua-site-v|-release)/g, '')}</Typography>
                    </Box>}
                </Box>
                <Divider />
                <Box display='flex' flexWrap='wrap' gap={3} sx={{ width: '100%' }}>
                    <Typography sx={textSx}>{t('copyright', { year: dayjs().year() })}</Typography>
                    {docs.flatMap(e => [
                        <Divider key={e.id + '-divider'} orientation='vertical' sx={{ height: '0.75rem', alignSelf: 'center' }} />,
                        <Link key={e.id} sx={textSx} onClick={()=>nav('/' + e.id)}>{e.title}</Link>,
                    ])}
                    <Box display='flex' justifyContent='flex-end' sx={{ flex: 1 }}><img alt='mogua logo' src='/images/omnimind_grey_icon.svg' width='100px' /></Box>
                </Box>
            </Stack>
        </Box>
    );
}

export default Footer;