import * as React from 'react';
import { useTranslation, Trans } from 'react-i18next';

import User from '../models/User';

import { contactUrl } from '../GlobalConfig';

import { HeroCard } from './HeroCard';
import { Typography, Divider, Box, Link } from '@mui/joy';


const NoDownloadUrl: React.FC = () => {
	const { t } = useTranslation('dashboard');
	return (<>
        <HeroCard title={t('noDownloadUrlTitle')}>
        	<Typography>{t('noDownloadUrlDesc')}</Typography>
			<br />
			<Divider inset='none' />
			<Box display='flex' flexDirection='row' alignItems='center' justifyContent='space-between'>
				<Typography fontSize='sm'><Trans t={t} i18nKey='noDownloadUrlHelp' components={[<Link href={contactUrl} />]} /></Typography>
			</Box>
    	</HeroCard>
    </>);
}

export default NoDownloadUrl;

