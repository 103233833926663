import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useLangNavigate as useNavigate } from '../modules/i18n';

import { AppWorkspaceContext } from './AppWorkspace';
import Data, { Platform, EventType } from '../models/Data';
import dayjs from 'dayjs';

import { Colors } from '../Resources';

import useNewbie from './Newbie';
import DataCard from './DataCard';
import { stringToHsl } from './AppIcon';
import { NotifyExpired } from './Notifications';
import { WorkspaceSection } from './WorkspaceSection';
import { Card, CardOverflow, CardContent } from '@mui/joy';
import { Box, BoxProps, Typography, IconButton, Chip, Divider, Grid } from '@mui/joy';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome'
import { faXmark, faCircleArrowRight, faCircleCheck, faCircleMinus, faArrowsRotate, faBan } from '@fortawesome/free-solid-svg-icons'


const headerSx = { fontSize: 32, fontWeight: 'bold', color: Colors.black, letterSpacing: '-0.05rem' };

const AppOverview: React.FC = () => {
	const app = React.useContext(AppWorkspaceContext)!;
	const [newbie, setNewbie] = useNewbie(app);
	const create = app.createAt.toLocaleDateString();
	const today = new Date().toLocaleDateString();
	const { t } = useTranslation('dashboard');

	React.useEffect(() => {
		if (!newbie || dayjs() < dayjs(app.createAt).add(3, 'd')) return;
		setNewbie(false);
	}, [app.createAt, newbie, setNewbie]);

	return (<>
		{app.isExpired() && <NotifyExpired />}
		<WorkspaceSection sx={{ flex: 1, px: 4, pt: 2, pb: 4 }}>
			<Typography noWrap sx={Object.assign({}, headerSx, { fontSize: 48 })}>{app.name}</Typography>
			{newbie && <Card sx={{ width: 'max-content', gap: 0, my: 2 }}>
				<CardOverflow sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', pr: 0.5, py: 0.5 }}>
					<Typography fontWeight='bold'>{t('newbie.welcome')}</Typography>
					<IconButton onClick={()=>setNewbie(false)}><FA icon={faXmark} /></IconButton>
				</CardOverflow>
				<CardContent sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 1 }}>
					<Typography sx={{ fontSize: 56 }}>🎉</Typography>
					<Box mr={4}>
						<Typography sx={{ fontSize: 32, fontWeight: 'bold', color: Colors.black, letterSpacing: '-0.05rem' }}>{t('newbie.promoTitle')}</Typography>
						<Typography sx={{ fontSize: 14, color: '#999' }}>
							{create === today ?
							t('newbie.promoSubtitleToday') :
							t('newbie.promoSubtitle', { date: create, interpolation: { escapeValue: false } })}
						</Typography>
					</Box>	
				</CardContent>
			</Card>}
			<GetStarted mt={2} />
			<Divider sx={{ my: 4 }} />
			<Today />
			{!newbie && <>
				<Divider sx={{ my: 4 }} />
				<Typography mb={1} sx={headerSx}>{t('sidebar.subscription')}</Typography>
				<Typography fontWeight='bold' startDecorator={app.subscription ? <FA icon={faArrowsRotate} /> : app.isExpired() ? <FA icon={faBan} /> : undefined}>
					{app.subscription ? 
					t('subscriptionRenews', { date: app.subscription.renewAt.toLocaleDateString(), desc: app.subscription.product.desc, interpolation: { escapeValue: false } }) : 
					app.isExpired() ?
					t('subscriptionExpired', { date: app.expireAt.toLocaleDateString(), interpolation: { escapeValue: false } }) : 
					t('subscriptionExpires', { date: app.expireAt.toLocaleDateString(), interpolation: { escapeValue: false } })}
				</Typography>
			</>}
		</WorkspaceSection>
	</>);
}

export default AppOverview;


const GetStarted: React.FC<BoxProps> = ({ ...props }) => {
	const app = React.useContext(AppWorkspaceContext)!;
	const { t } = useTranslation('dashboard');

	const chip = React.useCallback((platform: string, integrated: boolean) => (<Chip
		color={integrated ? 'success' : 'neutral'}
		startDecorator={<FA icon={integrated ? faCircleCheck : faCircleMinus} />}
		sx={{ bgcolor: integrated ? '#e8fae5' : Colors.grayBG, '--Chip-paddingInline': '4px 8px' }}
		variant='outlined'>{platform}{integrated ? '' : ' not integrated'}</Chip>), []);
	
	const nav = useNavigate();

	return app.integrates.web || app.integrates.ios || app.integrates.android ?
	<Box display='flex' flexWrap='wrap' alignItems='center' gap={2} {...props}>
		{chip('Web', app.integrates.web)}
		{chip('iOS', app.integrates.ios)}
		{chip('Android', app.integrates.android)}
	</Box> :
	<Box display='flex' flexDirection='column' gap={2} {...props}>
		<Typography sx={headerSx}>{t('getStarted')}</Typography>
		<Chip
			size='lg' endDecorator={<FA icon={faCircleArrowRight} style={{ color: app.icon ? 'black' : stringToHsl(app.name) }} />}
			sx={{ fontWeight: 'bold', '--Chip-paddingInline': '12px 8px' }}
			onClick={()=>nav('configuration')}>{t('getStartedApp')}</Chip>
		<Chip
			size='lg' endDecorator={<FA icon={faCircleArrowRight} style={{ color: app.icon ? 'black' : stringToHsl(app.name) }} />}
			sx={{ fontWeight: 'bold', '--Chip-paddingInline': '12px 8px' }}
			onClick={()=>nav('integration?ct=get-started')}>{t('getStartedSdk')}</Chip>
	</Box>;
}


const Today: React.FC = () => {
	const app = React.useContext(AppWorkspaceContext)!;
	const [data, setData] = React.useState();
	const [mpp, setMpp] = React.useState<{ TagName: string, TagValue: string, Count: number }>();
	const { t } = useTranslation(['dataCard', 'common']);

	React.useEffect(() => {
		const data = new Data({
			appId: app.id,
			paramKey: '',
			paramValue: '',
			platform: Platform.all,
			eventType: EventType.all,
			dateStart: dayjs().startOf('d'),
			dateEnd: dayjs().endOf('d'),
		});
		data.getOverview({
			onSuccess: (data) => {
				setData(data);
			},
			onError: (error) => {
				alert(error);
			},
		});
		data.getParameters({
			onSuccess: (data) => {
				data.sort((a: any, b: any) => b.Count - a.Count);
				setMpp(data[0]);
			},
			onError: (error) => {
				alert(error);
			},
		});
	}, [app.id]);

	return <>
		<Typography sx={headerSx} mb={1}>{t('today', { ns: 'common' })}</Typography>
		<Grid container spacing={2}>
			<Grid xs={3}><DataCard {...t('ad', { returnObjects: true })} number={data ? data[1] : 0} /></Grid>
			<Grid xs={3}><DataCard {...t('ai', { returnObjects: true })} number={data ? data[2] : 0} /></Grid>
			<Grid xs={3}><DataCard {...t('ao', { returnObjects: true })} number={data ? data[3] : 0} /></Grid>
			<Grid xs={3}><DataCard number={mpp?.Count ?? 0}
				title={`${mpp?.TagName ?? 'Key'} : ${mpp?.TagValue ?? 'Value'}`}
				subtitle={t('kv.subtitle')}
				tips={t('kv.tips')} />
			</Grid>
		</Grid>
	</>;
}


